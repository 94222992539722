<template>
  <b-table-simple responsive>
    <b-tbody>
      <b-tr>
        <b-th colspan="2" class="text-center"
          >RECURSOS DE APOYO A LA DOCENCIA
        </b-th>
      </b-tr>
      <b-tr
        v-for="resource_type in supportResourceTypesFilter"
        :key="`resource_type-${resource_type.id}`"
      >
        <b-td style="width: 25%">
          <strong>{{ resource_type.name.toUpperCase() }}:</strong>
          <div
            v-if="
              allows_crud &&
              oldMatter &&
              !oldMatter.is_closed &&
              isClosedOldMatterPartial(resource_type)
            "
          >
            <b-button
              @click="
                $bvModal.show(
                  `create-resource-modal-${resource_type.id}-${matter_form_view}`
                )
              "
              v-b-tooltip.v-secondary.noninteractive="
                'Agregue los Recursos por separado'
              "
              variant="primary"
              size="sm"
              class="mt-2 mb-2"
            >
              Agregar Recurso
            </b-button>
            <b-modal
              :id="`create-resource-modal-${resource_type.id}-${matter_form_view}`"
              title="Crear Recurso"
              size="lg"
              hide-footer
            >
              <NewRichTextEditor
                :Type="resource_type"
                @save="createSupportResource"
                @close="closeModal(resource_type.id)"
              ></NewRichTextEditor>
            </b-modal>
          </div>
        </b-td>
        <b-td>
          <div>
            <div
              v-for="resource in supportResourcesSort.filter(
                (r) => r.type == resource_type.id
              )"
              :key="`resource-${resource.id}`"
              class="border-resource"
            >
              <div style="display: flex">
                <div
                  class="my-1 p-1 w-100 rich-text-content support-resource-content"
                  v-html="resource.description"
                ></div>
                <div
                  v-if="
                    allows_crud &&
                    oldMatter &&
                    !oldMatter.is_closed &&
                    isClosedOldMatterPartial(resource_type)
                  "
                  class="container-btn-resources"
                >
                  <div class="d-flex p-1">
                    <button-edit
                      tabindex="-1"
                      class="w-30"
                      @click="
                        $bvModal.show(
                          `edit-resource-modal-${resource.id}-${matter_form_view}`
                        )
                      "
                    >
                    </button-edit>
                    <button-delete
                      tabindex="-1"
                      class="w-30"
                      @click="
                        deleteSupportResource(resource.id, resource_type.name)
                      "
                    ></button-delete>
                  </div>
                  <b-modal
                    :id="`edit-resource-modal-${resource.id}-${matter_form_view}`"
                    title="Editar Recurso de Apoyo a la Docencia"
                    size="lg"
                    hide-footer
                  >
                    Copie y edite los Recursos por separado
                    <NewRichTextEditor
                      :Object="resource"
                      :Text="resource.description"
                      @save="updateSupportResource"
                      @close="closeModal(resource.id)"
                    ></NewRichTextEditor>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "TeachingSupportResourcesComponent",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    oldMatter: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    supportResources: {
      type: Array,
      required: true,
    },
    matter_form_view: {
      type: Boolean,
      default: false,
    },
    filter_object_in_view: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      support_resources: [...this.supportResources],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      supportResourceTypes: names.SUPPORT_RESOURCE_TYPES,
    }),
    supportResourceTypesFilter() {
      if (this.filter_object_in_view) {
        return this.supportResourceTypes.filter((x) =>
          this.$equals(x.name, "TECNOLOGÍA EDUCATIVA")
        );
      } else return this.supportResourceTypes;
    },
    supportResourcesSort() {
      let list = this.support_resources;
      return list.sort(function (a, b) {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
    },
  },
  methods: {
    isClosedOldMatterPartial(support_resource_type) {
      if (this.oldMatter) {
        if (
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        ) {
          if (
            this.$equals(support_resource_type.name, "TECNOLOGÍA EDUCATIVA")
          ) {
            return !this.oldMatter.education_technology_locked_view;
          } else return true;
        } else {
          return !this.oldMatter.education_technology_locked_view;
        }
      } else return false;
    },
    deleteSupportResource(resource_id, type_name) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${type_name}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/teaching/support-resource/${resource_id}/`)
            .then(() => {
              let index = this.support_resources.findIndex(
                (r) => r.id == resource_id
              );
              if (index != -1) {
                this.$emit("change_teaching_support_resources");
                this.support_resources.splice(index, 1);
                toast("Se eliminó el Recurso Apoyo a la Docencia.");
              }
            });
        }
      });
    },
    updateSupportResource(resource, text) {
      let item = {
        name: text,
        description: text,
      };
      this.$restful
        .Patch(`/teaching/support-resource/${resource.id}/`, item)
        .then((response) => {
          const index = this.support_resources.findIndex(
            (b) => b.id == resource.id
          );
          if (index != -1) {
            this.$emit("change_teaching_support_resources");
            this.support_resources[index].description = response.description;
            this.$bvModal.hide(
              `edit-resource-modal-${resource.id}-${this.matter_form_view}`
            );
            toast("Se actualizó el Recurso Apoyo a la Docencia.");
          }
        });
    },
    closeModal(resource_id) {
      this.$bvModal.hide(
        `edit-resource-modal-${resource_id}-${this.matter_form_view}`
      );
      this.$bvModal.hide(
        `create-resource-modal-${resource_id}-${this.matter_form_view}`
      );
    },
    createSupportResource(text, resource_type) {
      if (this.oldMatter.id) {
        let item = {
          name: text,
          description: text,
          type: resource_type.id,
          matter: this.oldMatter.id,
        };
        this.$restful
          .Post(`/teaching/support-resource/`, item)
          .then((response) => {
            this.$emit("change_teaching_support_resources");
            this.support_resources.push(response);
            this.$bvModal.hide(
              `create-resource-modal-${resource_type.id}-${this.matter_form_view}`
            );
          });
      }
    },
    fetchSupportResource(matter_id) {
      this.$restful
        .Get(`/teaching/support-resource/?matter=${matter_id}`)
        .then((response) => {
          this.support_resources = response;
        });
    },
  },
  created() {
    if (this.supportResources.length == 0) {
      this.fetchSupportResource(this.oldMatter.id);
    }
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.container-btn-resources {
  display: flex;
  align-items: center;
}
.border-resource {
  border-bottom: 1px solid black;
}
.border-resource:last-child {
  border-bottom: none;
}
table {
  width: 100%;
  table-layout: auto;
}
table td {
  border: 1px solid black;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 0px 0px;
}
table th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color) !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.th-header {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: var(--primary-font-size);
}
@media print {
  .bibliography-content,
  .support-resource-content {
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 800px !important;
  }
}
</style>